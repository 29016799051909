<template>
  <div class="zbody">
    <div class="box pad" id="yxym">
      <div class="list-title">2024年属性知识</div>
      <table border="1" width="100%" class="gongshi" bgcolor="#ffffff" cellspacing="0" bordercolor="#FFFFFF"
        bordercolorlight="#FFFFFF" bordercolordark="#FFFFFF" cellpadding="2">
        <tbody>
          <tr>
            <td>
              <div class="sx grey-line">
                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                  <tbody>
                    <tr>
                      <th>２０２４龍年（十二生肖号码对照）</th>
                    </tr>
                    <tr>
                      <td>
                        <ul class="sx1 clearfix">
                          <li v-for="(item, index) in sxdzList" :key="index">
                            <dl>
                              <dt>
                                {{ item.name
                                }}<img :src="require('@/assets/images/' +
    item.className +
    '.gif')
  " />[冲 {{ item.chong }}]
                              </dt>
                              <dd class="shengxiao_list">
                                <span :class="getNumberColor(ele)" v-for="(ele, i) in item.list" :key="i">{{ ele }}</span>
                              </dd>
                            </dl>
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                  <colgroup>
                    <col width="50" />
                    <col width="" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th colspan="2">五行对照</th>
                    </tr>
                    <tr v-for="(item, index) in wxdzList" :key="index">
                      <td>
                        <font :class="getWxdzColor(item.name)">{{
                          item.name
                        }}</font>
                      </td>
                      <td class="a-left">
                        <span :class="getNumberColor(ele)" v-for="(ele, i) in item.list" :key="i">{{ ele }}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                  <colgroup>
                    <col width="50" />
                    <col width="" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th colspan="2">波色</th>
                    </tr>
                    <tr v-for="(item, index) in bsList" :key="index">
                      <td>
                        <font :color="getBsColor(item.name)">{{
                          item.name
                        }}</font>
                      </td>
                      <td class="a-left">
                        <span :class="getBsColor(item.name)" v-for="(ele, i) in item.list" :key="i">{{ ele }}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                  <colgroup>
                    <col width="50" />
                    <col width="" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th colspan="2">合数单双</th>
                    </tr>
                    <tr v-for="(item, index) in hsdsList" :key="index">
                      <td>
                        <font color="#3366ff">{{ item.name }}</font>
                      </td>
                      <td class="a-left">
                        <span :class="getNumberColor(ele)" v-for="(ele, i) in item.list" :key="i">{{ ele }}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                  <tbody>
                    <tr>
                      <th>生肖属性</th>
                    </tr>
                    <tr v-for="(item, index) in sxsxList" :key="index">
                      <td>
                        <font color="#3366ff">{{ item.name }}：</font>
                        <template v-for="(ele, i) in item.list">
                          {{ ele }}{{ i != item.list.length - 1 ? "、" : "" }}
                        </template>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import "@/assets/css/style4.less";
import "@/assets/css/comparison.less";
export default {
  name: "bankaccount",

  data() {
    return {
      // 生肖属性
      sxsxList: [],
      // 波色
      bsList: [],
      // 合数单双
      hsdsList: [],
      // 五行对照
      wxdzList: [],
      // 生肖对照
      sxdzList: [],
    };
  },
  mounted() {
    console.log(JSON.stringify(this.sxdzList));
    //波色
    this.getbsData();
    //生肖属性
    this.getsxsxData();
    //合数单双
    this.gethsdsData();
    // 五行对照
    this.getwxdzData();
    // 生肖对照
    this.getsxdzData();
  },
  methods: {
    async getsxsxData() {
      this.sxsxList = await this.$api.home.getsxsxData();
    },
    async getbsData() {
      this.bsList = await this.$api.home.getbsData();
    },
    async gethsdsData() {
      this.hsdsList = await this.$api.home.gethsdsData();
    },
    async getwxdzData() {
      this.wxdzList = await this.$api.home.getwxdzData();
    },
    async getsxdzData() {
      this.sxdzList = await this.$api.home.getsxdzData();
    },
    //获取波色
    getBsColor(e) {
      switch (e) {
        case "红波":
          return "red";
        case "蓝波":
          return "blue";
        case "绿波":
          return "green";
        default:
          return "red";
      }
    },
    //通过数字获取对应颜色
    getNumberColor(e) {
      let color = "red";
      //遍历获取对应颜色
      this.bsList.forEach((element) => {
        element.list.forEach((ele) => {
          if (ele == e) {
            //拿到匹配的颜色对象。通过名称获取对应波色
            color = this.getBsColor(element.name);
          }
        });
      });
      //全部走完了还没满足的则走默认的。红色

      return color;
    },
    //获取五行对照颜色class
    getWxdzColor(e) {
      switch (e) {
        case "金":
          return "wx-jin";
        case "木":
          return "wx-mu";
        case "水":
          return "wx-shui";
        case "火":
          return "wx-huo";
        case "土":
          return "wx-tu";
        default:
          return "wx-jin";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.shengxiao_list {
  margin: 0px 1px;

  transform: scale(0.85);


}

.shengxiao_list span:nth-child(5) {
  transform: translateX(-25%);

}
</style>
