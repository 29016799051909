var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rollText",style:({ width: _vm.width, height: _vm.height })},[_c('em',{staticClass:"sport-son",style:({
    animation: `sport ${_vm.text.length > _vm.rollTextSize ? _vm.second : 0
      }s linear infinite`,
  })},[_vm._v(" "+_vm._s(_vm.text)+" ")]),_c('em',{staticClass:"sport-last",style:({
    animation: `sportlast ${_vm.text.length > _vm.rollTextSize ? _vm.second : 0
      }s linear infinite`,
  })},[_vm._v(" "+_vm._s(_vm.text)+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }