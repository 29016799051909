/**
文字滚动组件
 */
<template>
  <div class="rollText" :style="{ width, height }">
    <em class="sport-son" :style="{
      animation: `sport ${text.length > rollTextSize ? second : 0
        }s linear infinite`,
    }">
      {{ text }}
    </em>
    <em class="sport-last" :style="{
      animation: `sportlast ${text.length > rollTextSize ? second : 0
        }s linear infinite`,
    }">
      {{ text }}
    </em>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    //动态绑定样式，废弃
    // style: {
    //   type: Object,
    //   default: () => {
    //     return {
    //       width: "200px",
    //       height: "30px",
    //     };
    //   },
    // },

    //轮播文字的宽度展示
    width: {
      type: String,
      default: "200px",
    },
    //轮播文字的高度展示
    height: {
      type: String,
      default: "30px",
    },
    //轮播文字的内容
    text: {
      type: String,
      default: "",
    },
    //动画的时间，越大则轮播速度越慢
    second: {
      type: String,
      default: "10",
    },
    //文字大小超过n个则轮播
    rollTextSize: {
      type: Number,
      default: 6,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState([]),
  },
  mounted() {
    // this.text+="     ";
  },
  beforeDestroy() { },
  methods: {},
  components: {},
};
</script>
<style lang="less" >
.rollText {
  position: relative;
  // width: 200px;
  // height: 200px;
  overflow: hidden;

  >em {
    display: inline-block;
    font-style: normal;
    white-space: nowrap;
  }

  .sport-son {
    position: absolute;
    white-space: nowrap;
  }

  /* 鼠标经过停止运动 */
  .sport-son:hover {
    animation-play-state: paused;
  }

  p {
    display: inline;
  }

  @keyframes sport {
    0% {}

    100% {
      /* 父盒子宽度减去100% */
      transform: translateX(-110%);
    }
  }

  @keyframes sportlast {
    0% {
      transform: translateX(110%);
    }

    100% {
      /* 父盒子宽度减去100% */
      transform: translateX(0%);
    }
  }
}
</style>
