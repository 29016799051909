<!-- LazyLoadComponent.vue -->
<template>
  <div ref="lazyLoadContainer" class="lazy-load-container">
    <div v-if="isVisible" v-html="content"></div>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isVisible: false,
    };
  },
  mounted() {
    this.createObserver();
  },
  methods: {
    createObserver() {
      const options = {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      };

      this.observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.isVisible = true;
            this.observer.disconnect();
          }
        });
      }, options);

      this.observer.observe(this.$refs.lazyLoadContainer);
    },
  },
};
</script>

<style>
.lazy-load-container {
  /* min-height: 50px; */
  margin: 10px 0;
  border: 1px solid #eee;
  padding: 10px;
}
</style>
