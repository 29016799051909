<template>
  <div class="container">
    <img src="../../assets/img/tb_bg.jpg" style="width: 100%; height: auto" />
    <ul class="option-show">
      <li
        :class="item.name == currentNavName ? 'active' : ''"
        v-for="(item, index) in navList"
        :key="index"
      >
        <a :style="item.style" href="#" @click="toNav(item)">{{ item.name }}</a>
      </li>
    </ul>
    <div class="panel">
      <div class="panel-header">
        <div class="visit-show">
          <span id="sitename" style="color: #00f">{{ currentNavName }}</span>
          <font color="#ff0000"
            ><span id="q">{{ kjjgData.resJKDb.preDrawIssue || "-" }}</span
            >期</font
          >开奖结果&nbsp;<span id="jilu"
            ><a
              @click="goResList"
              target="_top"
              style="color: red; text-decoration: none"
              >开奖记录</a
            ></span
          >
        </div>
      </div>
       
      <div class="van-col panel-body van-col--24 box-shad">
        <div
          class="van-row van-row--align-center van-row--justify-space-between border-box"
        >
          <div
            class="van-col van-col--3"
            v-for="(item, index) in from6List"
            :key="index"
          >
            <div
              class="bg-border-blue bg-border-style"
              :class="getNumberColor(item.num)"
            >
              <span class="span1">{{ item.num }}</span
              ><span class="span2">{{ item.wuxing }}/{{ item.shuxiang }}</span>
            </div>
          </div>
          <div class="van-col icon">
            <i class="van-badge__wrapper van-icon van-icon-plus"> </i>
          </div>
          <div
            class="van-col van-col--3"
            v-for="(item, index) in lastList"
            :key="index"
          >
            <div
              class="bg-border-blue bg-border-style"
              :class="getNumberColor(item.num)"
            >
              <span class="span1">{{ item.num }}</span
              ><span class="span2">{{ item.wuxing }}/{{ item.shuxiang }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="panel-footer">
        <p align="center">
          <span id="nextQiShu" style="color: red">{{
            kjjgData.resJKDb.drawIssue || "-"
          }}</span
          >期:开奖时间<span id="nextMonth" style="color: red">{{
            kjjgData.resJKDb.drawTime || "-"
          }}</span
          ><span id="nextPeriodWeek" style="color: red">{{
            kjjgData.nextPeriodWeek || ""
          }}</span
          ><span id="nextTime" style="color: red">{{
            kjjgData.nextPeriodTime || ""
          }}</span
          ><a class="refresh" @click="refresh">刷新</a>
        </p>

        <div id="article" style="color: #fff; height: 0.1px; overflow: hidden">
          澳门105期开奖结果：19[狗][红][土]-07[狗][红][木]-14[兔][蓝][木]-10[羊][蓝][金]-30[猪][红][火]-16[牛][绿][火]-49[龙][绿][土]
          (101896点com)
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/css/kj-style.css";
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      //导航列表
      navList: [
        {
          name: "新澳门论坛",
          style: "color: #ff17e2",
        },
        {
          name: "港彩六合",
        },
        {
          name: "澳门六合",
        },
        // {
        //   name: "香港六合",
        // },
      ],
      currentNavName: "新澳门论坛", //当前主题名称
      //开奖结果
      kjjgData: {
        // address: "澳门", //地址
        // period: "105", //期数
        // nextPeriod: "106", //下一期
        // nextPeriodMonth: "04", //下一期月份
        // nextPeriodDay: "15", //下一期日
        // nextPeriodWeek: "一", //下一期周几
        // nextPeriodTime: "21:30", //下一期时间
        // numberList: [
        //   {
        //     num: "19", //号码
        //     shuxiang: "狗", //属相
        //     wuxing: "土", //五行
        //   },
        //   {
        //     num: "07", //号码
        //     shuxiang: "狗", //属相
        //     wuxing: "木", //五行
        //   },
        //   {
        //     num: "14", //号码
        //     shuxiang: "兔", //属相
        //     wuxing: "木", //五行
        //   },
        //   {
        //     num: "10", //号码
        //     shuxiang: "羊", //属相
        //     wuxing: "金", //五行
        //   },
        //   {
        //     num: "30", //号码
        //     shuxiang: "猪", //属相
        //     wuxing: "火", //五行
        //   },
        //   {
        //     num: "16", //号码
        //     shuxiang: "牛", //属相
        //     wuxing: "火", //五行
        //   },
        //   {
        //     num: "49", //号码
        //     shuxiang: "龙", //属相
        //     wuxing: "土", //五行
        //   },
        // ],
      },
      bsList: [],
      from6List: [], //前面六个数字
      lastList: [], //最后一个数字
      openTimer: null, //开奖定时器效果
      timeV: 5 * 1000, //开奖延时
      openIndex: 0, //开奖下标
      refreshTimer: null, //刷新定时器
    };
  },
  computed: {},
  created() {
    //波色
    this.getbsData();
    //获取开奖结果
    this.getkjjgData();
  },
  mounted() {
    console.log(JSON.stringify(this.kjjgData));
    this.refreshTimer = setInterval(() => {
      this.getkjjgData();
    }, 10 * 1000);
  },
  watch: {
    currentNavName: {
      handler() {
        this.getkjjgData();
      },
    },
  },
  beforeDestroy() {
    this.openTimer && clearInterval(this.openTimer);
    this.refreshTimer && clearInterval(this.refreshTimer);
  },
  methods: {
    //启动开奖效果
    startOpen() {
      //先全部变黑
      this.from6List = this.from6List.map((e) => {
        e.noNone = false;
        return e;
      });
      this.lastList = this.lastList.map((e) => {
        e.noNone = false;
        return e;
      });
      this.openTimer && clearInterval(this.openTimer); //清空定时器
      this.openIndex = 0; //恢复下标
      //开启开奖定时器
      this.openTimer = setInterval(() => {
        this.open();
      }, this.timeV);
    },
    open() {
      this.$forceUpdate();
      console.log("开奖号码" + (this.openIndex + 1));

      if (this.openIndex < 6) {
        this.from6List[this.openIndex] = {
          ...this.from6List[this.openIndex],
          noNone: true,
        };
      } else if (this.openIndex == 6) {
        this.lastList[0] = {
          ...this.lastList[0],
          noNone: true,
        };
        console.log("开奖详情last", this.lastList);
      }

      // console.log("开奖详情", this.from6List[this.openIndex]);
      this.$forceUpdate();
      this.openIndex++;
      if (this.openIndex > 6) {
        this.openTimer && clearInterval(this.openTimer);
        return;
      }
    },
    refresh() {
      this.getkjjgData();
    },
    goResList() {
      this.$router.push({
        path: "/reslist",
        query: { type: this.currentNavName },
      });
    },
    async getkjjgData() {
      let res;
      if (this.currentNavName == "新澳门论坛") {
        res = await this.$api.home.getXYC_ZUIJIN_kjjgData();
      }
      if (this.currentNavName == "香港六合") {
        res = await this.$api.home.get_zuijin_amxgkjjgData("gflhc");
      }
      if (this.currentNavName == "澳门六合") {
        res = await this.$api.home.get_zuijin_amxgkjjgDatanew("2");
      }
      if (this.currentNavName == "港彩六合") {
        res = await this.$api.home.get_zuijin_amxgkjjgDatanew("1");
      }
      this.kjjgData = res[0];
      console.log(this.kjjgData);
      //处理号码
      this.tramsNumber();
      //处理时间
      // let isStart = this.tramsTime();
      // if (isStart) {
      //   console.log("距离开奖小于20s");
      //   //启动开奖效果
      //   this.startOpen();
      // }
    },
    tramsTime() {
      console.log(this.kjjgData);
      console.log(new Date(this.kjjgData.resJKDb.drawTime).getTime());
      console.log(new Date().getTime());
      console.log(
        new Date(this.kjjgData.resJKDb.drawTime).getTime() -
          new Date().getTime()
      );
      let isStart =
        new Date(this.kjjgData.resJKDb.drawTime).getTime() -
          new Date().getTime() <
        20 * 1000;
      //小于20s开始播放动画
      console.log(isStart);
      return isStart;
    },
    toNav(e) {
      this.currentNavName = e.name;
    },
    async getbsData() {
      this.bsList = await this.$api.home.getbsData();
    },
    //处理号码0-6，7
    tramsNumber() {
      this.from6List = JSON.parse(
        JSON.stringify(this.kjjgData.numberList.splice(0, 6))
      );
      let lastindex =
        this.kjjgData.numberList.length >= 6
          ? 6
          : this.kjjgData.numberList.length - 1;
      this.lastList = [this.kjjgData.numberList[lastindex]];
    },
    //获取五行对照颜色class
    getWxdzColor(e) {
      switch (e) {
        case "金":
          return "wx-jin";
        case "木":
          return "wx-mu";
        case "水":
          return "wx-shui";
        case "火":
          return "wx-huo";
        case "土":
          return "wx-tu";
        default:
          return "wx-jin";
      }
    },
    //获取波色
    getBsColor(e) {
      switch (e) {
        case "红波":
          return "red";
        case "蓝波":
          return "blue";
        case "绿波":
          return "green";
        default:
          return "red";
      }
    },
    //通过数字获取对应颜色
    getNumberColor(e) {
      let color = "red";
      //遍历获取对应颜色
      this.bsList.forEach((element) => {
        element.list.forEach((ele) => {
          if (ele == e) {
            //拿到匹配的颜色对象。通过名称获取对应波色
            color = this.getBsColor(element.name);
          }
        });
      });
      //全部走完了还没满足的则走默认的。红色

      return color;
    },
  },
};
</script>
<style lang="less" scoped>
.line {
  margin-top: 5px;
  border-top: 2px black solid;

  height: 30px;

  width: 100%;
}
.box-shad {
  box-shadow: inset 0 -0.5px 0 #f5f5f5;
}

.van-col--24 {
  flex: 0 0 100%;
  max-width: 100%;
}
.van-col {
  display: block;
  box-sizing: border-box;
  min-height: 1px;
}
.van-row--align-center {
  align-items: center;
}
.van-row--justify-space-between {
  justify-content: space-between;
}
.van-row {
  display: flex;
  flex-wrap: wrap;
}
.border-box .van-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 12.5%;
}
.van-col--3 {
  flex: 0 0 12.5%;
  max-width: 12.5%;
}
.bg-border-style {
  width: 44px;
  height: 44px;
  background-size: contain;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 6px;
}
.bg-border-style .span1 {
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  color: #000;
  font-size: 22px;
}
.bg-border-style .span2 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  background: transparent;
  font-size: 13px;
  color: #666;
  margin-top: 5px;
}
</style>
