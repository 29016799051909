<template>
  <div class="describe">
    <!-- <table
      border="1"
      width="100%"
      cellpadding="0"
      cellspacing="0"
      bgcolor="#FFFFFF"
      bordercolor="#c0c0c0"
      style="border-collapse: collapse"
    >
      <tbody>
        <tr>
          <td
            align="left"
            style="
              font-weight: 700;
              font-family: Tahoma;
              color: #0000ff;
              font-size: 14pt;
              line-height: 24px;
            "
          >
            111期一字記之曰：<font color="#FF0000"
              >【困】四肢不勤，有志难伸，莫怨英雄遭运困。
              天助自助，出路自寻，休道天界瞥被面。《刷》</font
            ><br />
            <font color="#330099"
              >解：休道天冧当被冚：天冧解天肖兔猪马猴牛龙。</font
            ><br />
            <font color="#FF00FF">综合取肖：兔猪马猴牛龙</font>
          </td>
        </tr>
      </tbody>
    </table>
    <table
      border="1"
      width="100%"
      cellpadding="0"
      cellspacing="0"
      bgcolor="#FFFFFF"
      bordercolor="#c0c0c0"
      style="border-collapse: collapse"
    >
      <tbody>
        <tr>
          <td
            align="left"
            style="
              font-weight: 700;
              font-family: Tahoma;
              color: #0000ff;
              font-size: 14pt;
              line-height: 24px;
            "
          >
            109期一字記之曰：<font color="#FF0000"
              >【刻】刻薄划削，悉随尊便。抵力反睾非自顾。天生我材，叫苦速天。枉有壮志雕伸展。《脚》</font
            ><br />
            <font color="#330099"
              >解：天生我材，叫苦速天，凶丑:鼠牛虎猴狗猪。</font
            ><br />
            <font color="#FF00FF">综合取肖：鼠牛虎猴<span>狗</span>猪</font>
          </td>
        </tr>
      </tbody>
    </table>
    <table
      border="1"
      width="100%"
      cellpadding="0"
      cellspacing="0"
      bgcolor="#FFFFFF"
      bordercolor="#c0c0c0"
      style="border-collapse: collapse"
    >
      <tbody>
        <tr>
          <td
            align="left"
            style="
              font-weight: 700;
              font-family: Tahoma;
              color: #0000ff;
              font-size: 14pt;
              line-height: 24px;
            "
          >
            108期一字記之曰：<font color="#FF0000"
              >【耍】鲧绦入扣，分毫不差。工多萎熟最耍家。天才庸才，难分高下。勤能酺拙真不假。《地》</font
            ><br />
            <font color="#330099"
              >解：鲧绦入扣，分毫不差，吉美:兔龙蛇马羊鸡。</font
            ><br />
            <font color="#FF00FF">综合取肖：兔龙蛇马<span>羊</span>鸡</font>
          </td>
        </tr>
      </tbody>
    </table> -->
    <div v-html="htmlObj.cpzjHtml"></div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["htmlObj"]),
  },
  name: "bankaccount",
  data() {
    return {
      contentData: [],
      html: ``,
    };
  },
  mounted() {
    console.log(JSON.stringify(this.contentData));
    // 开奖列表
    // this.getkjlbData();
  },
  methods: {
    async getkjlbData() {
      this.contentData = await this.$api.home.getkjlbData();
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  display: table;
  width: 100%;
  text-align: center;
  border-collapse: collapse;
  // border-spacing: 0;
  box-sizing: border-box;
  text-indent: initial;
}
table {
  border-collapse: collapse;
  // border-spacing: 0;
}
table td {
  border: 1px solid #e5e5e5;
  word-break: break-all;
}
</style>
