<template>
  <div class="zbody">
    <!-- <table border="1" class="content_right" width="100%">
      <tbody>
        
      </tbody>
    </table> -->
    <div v-for="(item, index) in contentData" :key="index">
      <div class="khung khung01">
        <div
          v-if="
            item.order_id == -1 || item.order_id == 0 || item.order_id == -2
          "
        ></div>

        <div v-else>
          <div style="margin-left: 50px; display: flex">
            <div class="khung_before">{{ item.title }}</div>
            <span class="khung_zhongjian">【{{ item.typeName }}】</span>
            <div class="khung_after">{{ item.webAdress }}</div>
          </div>
        </div>
      </div>
      <LazyLoadComponent v-if="item.order_id != 0" :content="item.valueStr"/>
      <!-- <div v-if="item.order_id != 0" v-html="item.valueStr"></div> -->

      <div v-if="item.order_id == -1">
        <div class="line"></div>
        <div class="text">
          <div style="font-size: 15px; color: red !important">
            {{ titleContext.title }}：
          </div>
          <RollText
            style="font-size: 15px"
            :width="'270px'"
            :text="titleContext.webAdress"
          ></RollText>
        </div>
      </div>
    </div>
    <!-- <div
      v-for="(item, index) in contentData"
      :key="index"
      v-html="item.valueStr"
    ></div> -->
  </div>
</template>

<script>
import { mapState } from "vuex";
import RollText from "./RollText.vue";
import LazyLoadComponent from "@/components/common/LazyLoadComponent.vue";
export default {
  components: {
    RollText,
    LazyLoadComponent,
  },
  computed: {
    ...mapState(["htmlObj"]),
  },
  name: "bankaccount",
  data() {
    return {
      contentData: [],
      zcontentHtml: ``,

      titleContext: {},
    };
  },
  mounted() {
    // 开奖列表
    this.getAllHtmlObjData();
  },
  methods: {
    async getAllHtmlObjData() {
      let res = await this.$api.home.getAllHtmlObjData();

      this.titleContext = res.filter((b) => b.order_id == -1)[0];

      console.log(this.titleContext);

      this.contentData = res.map((e) => {
        e.valueStr = decodeURIComponent(e.valueStr);
        return e;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.zbody {
  .line {
    border-top: 2px #c0c0c0 solid;
    height: 15px;
  }

  .text {
    margin-left: 11%;
    height: 30px;
    width: 100%;
    line-height: 15px;

    display: flex;
  }
}

html,
body {
  background: url(https://img1.shanghaixiaochagu.com/726322/images/409.jpg)
    fixed;
}

.ys span {
  background-color: #ffff00;
  color: #ff0000;
}

a {
  text-decoration: none;
}

img {
  vertical-align: middle;
}

.lt1 img {
  height: 278px;
  width: 49.6%;
  vertical-align: middle;
}

@media screen and (max-width: 600px) {
  .lt1 img {
    height: auto;
    width: 100%;
    vertical-align: middle;
  }
}

* {
  margin: 0;
  padding: 0;
}

.fsdh {
  background-color: #ff0;
  font-family: "微软雅黑";
  font-size: 12pt;
  color: #000;
  font-weight: 500;
  border: solid 1px #c0c0c0;
  line-height: 2rem;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

tr.td {
  color: #000;
}

.tb2 b {
  float: right;
  margin-right: 2px;
  font-weight: 400;
}

.cgi-foot-links,
#allsite,
#popMore {
  left: 50%;
  margin-left: -400px;
}

@media screen and (max-width: 600px) {
  .cgi-foot-links,
  #allsite,
  #popMore {
    left: auto;
    margin-left: auto;
  }
}

.cgi-foot-links,
#allsite,
#popMore {
  left: 50%;
  margin-left: -360px;
  max-width: 720px;
}

@media screen and (max-width: 600px) {
  .cgi-foot-links,
  #allsite,
  #popMore {
    left: auto;
    margin-left: auto;
  }
}

.content_right {
  width: 100%;
  text-align: center;
  font-weight: 700;
  border: solid 1px #ddd;
  border-collapse: collapse;
}

.content_right a {
  color: red;
}

.content_right b {
  background-color: yellow;
}

.content_right th {
  width: 100%;
  font-weight: 700;
  background-color: #ff0000;
  color: black;
  border: solid 1px #ddd;
  line-height: 30px;
  font-family: Arial Black;
  font-size: 18px;
}

.content_right tr td:nth-child(1) {
  width: 23%;
  font-weight: 700;
}

.content_right tr td:nth-child(3) {
  width: 21%;
  font-weight: 400;
}

.content_right tr td:nth-child(1),
.content_right tr td:nth-child(3) {
  text-align: center;
  font-size: 14px;
  line-height: 30px;
  border: solid 1px #ddd;
  color: black;
  font-family: tahoma;
  background-color: #ccff66;
}

.content_right tr td:nth-child(2) {
  font-family: tahoma;
  line-height: 30px;
  border: solid 1px #ddd;
  font-size: 14px;
  font-weight: 700;
  background-color: #ffffb5;
  color: #ff0000;
}

.colors {
  background-image: url(https://img1.shanghaixiaochagu.com/726322/images/nhay.jpg);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-background-size: 200% 100%;
  -webkit-animation: colorChange 6s infinite;
  display: inline-block;
}

@-webkit-keyframes colorChange {
  0% {
    background-position-x: 0;
    background-position-y: 0;
  }

  50% {
    background-position-x: -885px;
    background-position-y: -233px;
  }

  100% {
    background-position-x: 0;
    background-position-y: 0;
  }
}

.abc {
  display: inline-block;
  width: 98px;
  color: #ffff00;
  background: #dd3221;
  margin: 0 auto;
  border-radius: 18px;
  animation: dong 2s linear infinite;
  animation-fill-mode: both;
  box-shadow: 0 0 10px #ffff00;
  transition: color 0.4s;
  text-shadow: -1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000;
}

.abcd {
  display: inline-block;
  width: 94px;
  color: #ffff00;
  background: #dd3221;
  margin: 0 auto;
  border-radius: 18px;
  animation: dong 2s linear infinite;
  animation-fill-mode: both;
  box-shadow: 0 0 10px #ffff00;
  transition: color 0.4s;
  text-shadow: -1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000;
}

.abcc {
  display: inline-block;
  width: 146px;
  color: #ffff00;
  background: #dd3221;
  margin: 0 auto;
  border-radius: 18px;
  animation: dong 2s linear infinite;
  animation-fill-mode: both;
  box-shadow: 0 0 10px #ffff00;
  transition: color 0.4s;
  text-shadow: -1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000;
}

.abccc {
  display: inline-block;
  width: 180px;
  color: #ffff00;
  background: #dd3221;
  margin: 0 auto;
  border-radius: 18px;
  animation: dong 2s linear infinite;
  animation-fill-mode: both;
  box-shadow: 0 0 10px #ffff00;
  transition: color 0.4s;
  text-shadow: -1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000;
}

@keyframes dong {
  0% {
    transform: scaleX(1);
  }

  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  100% {
    transform: scaleX(1);
  }
}

table {
  display: table;
  width: 100%;
  text-align: center;
  // border-collapse: collapse;
  // border-spacing: 0;
  box-sizing: border-box;
  text-indent: initial;
}

.khung {
  line-height: 30px;
  background-image: url(https://img1.shanghaixiaochagu.com/726322/images/khung00.jpg);
  text-shadow: -1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000;
  background-position: center center;
  background-repeat: no-repeat;
  background-origin: padding-box;
  background-size: 250% 40px;
  font-size: 14pt;
  font-weight: 700;
  font-family: 楷体;
  border-collapse: collapse;
  text-align: center;
  display: flex;
  border: 1px solid #c0c0c0;
  color: #fff;
}

.khung_before {
  color: #41e3ff;
}

.khung_zhongjian {
  color: #fff;
}

.khung_after {
  color: #41e3ff;
}

.khungktt {
  width: 100%;
  line-height: 30px;
  font-size: 18px;
  font-weight: 700;
  font-family: Arial Black;
  border-collapse: collapse;
  text-align: center;
  border: 1px solid #000;
  color: #000;
  text-shadow: -1px 0 #fff, 0 1px #fff, 1px 0 #fff, 0 -1px #fff;
  background: linear-gradient(to top, #f00, #fac003);
}

.khungktt::before {
  content: "【王中王】";
}

.khungktt::after {
  content: "1312888.com";
}

.bg1 {
  text-align: center;
  font-size: 12pt;
  background: #ff0000;
  display: inline-block;
  width: 220px;
  border-radius: 20px;
  box-shadow: #000 0px 0px 5px 2px;
  color: #ffff00;
  text-shadow: -1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000;
}

.HaLan {
  margin-bottom: 5px;
  line-height: 20px;
  padding: 0 3px;
  font-size: 14pt;
  border: 1px solid #b21065;
  border-radius: 10px;
  background-image: linear-gradient(
      to bottom,
      #fff9,
      transparent 50% 90%,
      #0322ef99
    ),
    repeating-linear-gradient(60deg, #bc058255 0 10px, transparent 10px 20px),
    linear-gradient(to bottom, #060a09, #020704);
  color: #fff;
  font-family: monospace;
  font-weight: bold;
  text-shadow: 0 1px 0 #efb7de;
  box-shadow: 0 5px 0 #0005, 0 2.5px 0 #bd1360, 0 2px 0 3.5px #fff,
    0 0 15px 5px #e260ae;
  transition: 0.1s;
}

img {
  vertical-align: middle;
}

.HaLan1 {
  color: #ffffff;
  border-collapse: collapse;
  background-origin: padding-box;
  background-repeat: no-repeat;
  background-image: linear-gradient(to top, #5bf7e6, #000, rgb(30 233 168));
  font-weight: 700;
  -webkit-animation: Change 5s infinite;
  -webkit-background-size: 100% 300%;
  display: table-cell;
}

.HaLan2 {
  border-collapse: collapse;
  background-origin: padding-box;
  background-repeat: no-repeat;
  background-image: linear-gradient(to top, #ff0202, #5fffe3, #ec0e67);
  font-weight: 700;
  -webkit-animation: Change 10s infinite;
  -webkit-background-size: 100% 300%;
  display: table-cell;
}

.kttzjj {
  text-align: center;
  display: inline-block;
  width: 60px;
  color: #07b0da;
  background: #fff;
  border-radius: 18px;
  box-shadow: 0 0 10px #000;
  text-shadow: -1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000;
}

@-webkit-keyframes Change {
  0% {
    background-position-x: 0;
    background-position-y: 0;
  }

  50% {
    background-position-x: 0px;
    background-position-y: -33px;
  }

  100% {
    background-position-x: 0;
    background-position-y: 0;
  }
}

div.cont {
  padding: 0px;
  border-radius: 8px;
  box-shadow: 0px 0px 5px 2px #ff0000;
  color: #ff0000;
}

@keyframes tomm {
  0% {
    background-color: #46ffec;
    box-shadow: 0 0 3px #004a7f;
    color: #e91e63;
  }

  25% {
    background-color: #ec407a;
    box-shadow: 0 0 3px #ffeb3b;
    color: #ffff00;
  }

  50% {
    background-color: #ff0000;
    box-shadow: 0 0 10px #79f800;
    color: #fff;
  }

  75% {
    background-color: #0a1150;
    box-shadow: 0 0 10px #e91e63;
    color: #fff;
  }

  85% {
    background-color: #19b69c;
    box-shadow: 0 0 3px #9c27b0;
    color: #e91e63;
  }

  100% {
    background-color: #ffff00;
    box-shadow: 0 0 3px #004a7f;
    color: #fff;
  }
}

.sbb img {
  width: 35px;
  height: 35px;
}

.tomm {
  line-height: 30px;
  animation: tomm 1800ms infinite;
  display: inline-block;
  width: 215px;
  border-radius: 20px;
  text-shadow: -1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000;
}

@media screen and (max-width: 800px) {
  .tomm {
    line-height: 30px;
    animation: tomm 1800ms infinite;
    display: inline-block;
    width: 220px;
    font-size: 11pt !important;
    border-radius: 20px;
    text-shadow: -1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000;
  }
}
</style>
