import Vue from "vue";
import Vuex from "vuex";
import getters from "./getters";

import user from "./modules/user";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    htmlObj: {
      kjlbHtml: "",
      cplxHtml: "",
      cpgxHtml: "",
      cpzjHtml: "",
    },
  },
  mutations: {
    setHtmlObj(state, val) {
      state.htmlObj = val;
    },
  },
  modules: {
    user,
  },
  getters,
});

export default store;
