import Vue from "vue";
import VueRouter from "vue-router";

import {
  beforeEach,
  afterEach,
  beforeResolve
} from "./hook";

Vue.use(VueRouter);

const routes = [{
  path: "/",
  redirect: "/index"
},
{
  path: "/index",
  name: "index",
  component: () => import( /* webpackChunkName: "login" */ "../views/Index"),
  meta: {
    title: "官网",
    keepAlive: false,
  },
},
{
  path: "/reslist",
  name: "reslist",
  component: () => import( /* webpackChunkName: "login" */ "../views/Index/resList.vue"),
  meta: {
    title: "开奖记录",
    keepAlive: false,
  },
},
{
  path: "/template",
  name: "template",
  component: () => import( /* webpackChunkName: "login" */ "../views/Index/template.vue"),
  meta: {
    title: "模板",
    keepAlive: false,
  },
},
];

const router = new VueRouter({
  // mode: 'history',
  // base: "https://api.yourdomain.com", // 这里可以根据环境变量来设置
  routes,
});

router.beforeEach(beforeEach);
router.beforeResolve(beforeResolve);
router.afterEach(afterEach);

export default router;