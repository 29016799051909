var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"zbody"},[_c('div',{staticClass:"box pad",attrs:{"id":"yxym"}},[_c('div',{staticClass:"list-title"},[_vm._v("2024年属性知识")]),_c('table',{staticClass:"gongshi",attrs:{"border":"1","width":"100%","bgcolor":"#ffffff","cellspacing":"0","bordercolor":"#FFFFFF","bordercolorlight":"#FFFFFF","bordercolordark":"#FFFFFF","cellpadding":"2"}},[_c('tbody',[_c('tr',[_c('td',[_c('div',{staticClass:"sx grey-line"},[_c('table',{attrs:{"width":"100%","border":"0","cellspacing":"0","cellpadding":"0"}},[_c('tbody',[_vm._m(0),_c('tr',[_c('td',[_c('ul',{staticClass:"sx1 clearfix"},_vm._l((_vm.sxdzList),function(item,index){return _c('li',{key:index},[_c('dl',[_c('dt',[_vm._v(" "+_vm._s(item.name)),_c('img',{attrs:{"src":require('@/assets/images/' +
  item.className +
  '.gif')}}),_vm._v("[冲 "+_vm._s(item.chong)+"] ")]),_c('dd',{staticClass:"shengxiao_list"},_vm._l((item.list),function(ele,i){return _c('span',{key:i,class:_vm.getNumberColor(ele)},[_vm._v(_vm._s(ele))])}),0)])])}),0)])])])]),_c('table',{attrs:{"width":"100%","border":"0","cellspacing":"0","cellpadding":"0"}},[_vm._m(1),_c('tbody',[_vm._m(2),_vm._l((_vm.wxdzList),function(item,index){return _c('tr',{key:index},[_c('td',[_c('font',{class:_vm.getWxdzColor(item.name)},[_vm._v(_vm._s(item.name))])],1),_c('td',{staticClass:"a-left"},_vm._l((item.list),function(ele,i){return _c('span',{key:i,class:_vm.getNumberColor(ele)},[_vm._v(_vm._s(ele))])}),0)])})],2)]),_c('table',{attrs:{"width":"100%","border":"0","cellspacing":"0","cellpadding":"0"}},[_vm._m(3),_c('tbody',[_vm._m(4),_vm._l((_vm.bsList),function(item,index){return _c('tr',{key:index},[_c('td',[_c('font',{attrs:{"color":_vm.getBsColor(item.name)}},[_vm._v(_vm._s(item.name))])],1),_c('td',{staticClass:"a-left"},_vm._l((item.list),function(ele,i){return _c('span',{key:i,class:_vm.getBsColor(item.name)},[_vm._v(_vm._s(ele))])}),0)])})],2)]),_c('table',{attrs:{"width":"100%","border":"0","cellspacing":"0","cellpadding":"0"}},[_vm._m(5),_c('tbody',[_vm._m(6),_vm._l((_vm.hsdsList),function(item,index){return _c('tr',{key:index},[_c('td',[_c('font',{attrs:{"color":"#3366ff"}},[_vm._v(_vm._s(item.name))])],1),_c('td',{staticClass:"a-left"},_vm._l((item.list),function(ele,i){return _c('span',{key:i,class:_vm.getNumberColor(ele)},[_vm._v(_vm._s(ele))])}),0)])})],2)]),_c('table',{attrs:{"width":"100%","border":"0","cellspacing":"0","cellpadding":"0"}},[_c('tbody',[_vm._m(7),_vm._l((_vm.sxsxList),function(item,index){return _c('tr',{key:index},[_c('td',[_c('font',{attrs:{"color":"#3366ff"}},[_vm._v(_vm._s(item.name)+"：")]),_vm._l((item.list),function(ele,i){return [_vm._v(" "+_vm._s(ele)+_vm._s(i != item.list.length - 1 ? "、" : "")+" ")]})],2)])})],2)])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',[_vm._v("２０２４龍年（十二生肖号码对照）")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('colgroup',[_c('col',{attrs:{"width":"50"}}),_c('col',{attrs:{"width":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',{attrs:{"colspan":"2"}},[_vm._v("五行对照")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('colgroup',[_c('col',{attrs:{"width":"50"}}),_c('col',{attrs:{"width":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',{attrs:{"colspan":"2"}},[_vm._v("波色")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('colgroup',[_c('col',{attrs:{"width":"50"}}),_c('col',{attrs:{"width":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',{attrs:{"colspan":"2"}},[_vm._v("合数单双")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',[_vm._v("生肖属性")])])
}]

export { render, staticRenderFns }